import React from 'react';
// import { Builder, BuilderComponent } from '@builder.io/react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';

import * as styles from './404.module.css';

const PageNotFound = () => {
  // if (Builder.isPreviewing || Builder.isEditing) {
  //   return <BuilderComponent model="page" />;
  // }

  return (
    <Layout>
      <Seo title="Page Not Found" />
      <div className={styles.layout}>
        <Container>
          <h1 className={styles.title}>Oops!</h1>
          <p className={styles.description}>
            We can't seem to find the page you're looking for.
          </p>
          <span className={styles.errorCode}>Error code: 404</span>
        </Container>
      </div>
    </Layout>
  );
};

export default PageNotFound;
